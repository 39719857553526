import strategicPeopleImg from "../../../../media/services/people-inset-photo.jpg"
import strategicProcessImg from "../../../../media/services/process-inset-photo.jpg"
import strategicDataImg from "../../../../media/services/data-inset-photo.jpg"
import strategicTechnologyImg from "../../../../media/services/technology-inset-photo.jpg"

export const strategicCardsList = [
  {
    title: "People",
    description: "We provide guidance on organizational planning by identifying skills and teams needed to propel your business along its growth trajectory.",
    list: [
      "Identify organizational structure",
      "Evaluate current state and identify gaps",
      "Write job descriptions",
    ],
    image: strategicPeopleImg,
  },
  {
    title: "Process",
    description: "As your business evolves, we'll help restructure business processes and teams to ensure cross-functional workflows are efficient and effective.",
    list: [
      "Clarify roles, responsibilities, and dependencies",
      "Understand how people interact with technology",
      "Automate critical touchpoints, handoffs, and communication",
    ],
    image: strategicProcessImg,
  },
  {
    title: "Data",
    description: "We'll work with you to develop a strategy that maximizes the return on investment for your data.",
    list: [
      "Analyze market data spend",
      "Create data governance framework and stewardship model",
      "Identify opportunities to achieve straight-thru-processing",
    ],
    image: strategicDataImg,
  },
  {
    title: "Technology",
    description: "We analyze current and future state of your technology environment and develop a technology roadmap that allows you to scale your business and drive growth opportunities.",
    list: [
      "Develop technology roadmap",
      "Provide build vs. buy assessment",
      "Create target state operating model",
      "Evaluate and select vendors"
    ],
    image: strategicTechnologyImg,
  },
]
