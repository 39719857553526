import { makeStyles } from "@material-ui/core/styles"

export const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    flexGrow: 1,
    display: "flex",
    justifyContent: "center",
    padding: "0 60px",
    [theme.breakpoints.down("sm")]: {
      padding: 20,
    },
  },
  innerWrapper: {
    width: "100%",
    maxWidth: 1440,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  item: {
    width: "100%",
    marginBottom: 20,
    borderRadius: "0 !important",
    boxShadow: "none",
  },
  itemWrapper: {
    display: "flex",
    padding: "20px 40px",
    [theme.breakpoints.down("sm")]: {
      padding: 10,
      flexDirection: "column-reverse",
      "&.even": {
        background: "#e9eaec",    
      },
      "&.odd": {
      },
    },
    [theme.breakpoints.up("md")]: {
      padding: "20px 40px",
      "&.even": {
        flexDirection: "row",
        background: "#e9eaec",    
      },
      "&.odd": {
        flexDirection: "row-reverse",
      },
    },
  },
  itemDetails: {
    //
  },
  itemContent: {
    //
  },
  itemList: {
    listStyle: "square",
  },
  listItem: {
    marginBottom: 10,

    // "&::before": {
    //   content: "'•'",
    //   color: "#27aae1",
    //   fontWeight: "bold",
    //   display: "inline-block",
    //   width: "1em",
    //   marginLeft: "-1em",
    // },
  },
  listItemText: {
    //marginTop: "-1em",
    lineHeight: 1
  },
  itemCover: {
    padding: "0 20px",
    [theme.breakpoints.down("xs")]: {
      padding: 0,
    },
    [theme.breakpoints.up("sm")]: {
      padding: 10,
    },
  },
  itemCoverImage: {
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      height: 200,
    },
    [theme.breakpoints.up("sm")]: {
      width: "100%",
      height: 250,
    },
  },
}))
