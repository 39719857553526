import { makeStyles } from "@material-ui/core/styles"

export const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "60px 0 20px",
    [theme.breakpoints.down("sm")]: {
      padding: 20,
    },
  },
  innerWrapper: {
    width: "100%",
    maxWidth: 1440,
    display: "flex",
    flexDirection: "column",
    alignItems: "left",
  },
  title: {
    textAlign: "center",
    lineHeight: 1.2,
  },
  description: {
    textAlign: "center",
    lineHeight: 1.2,
    fontSize: "1.25rem",
    marginTop: 20,
  },
  subheading: {
    textAlign: "left",
    marginTop: 80,
    marginBottom: 20,
    marginLeft: 0,
    fontSize: "1.8rem"
  }
}))
