import React from "react"
import { Card, CardContent, CardMedia, Typography, Grid } from "@material-ui/core"

import { strategicCardsList } from "./data"
import { useStyles } from "./styles"

const StrategicAdvisoryCards = () => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <div className={classes.innerWrapper}>
      {strategicCardsList.map((item, index) => (
        <Card
          key={`strategic-card-${index}`}
          className={classes.item}
        >
          <Grid
            container
            className={`${classes.itemWrapper} ${index % 2 === 0 ? 'even' : 'odd'}`}
          >
            <Grid xs={12} sm={12} md={7} lg={8} xl={8}
              item
              className={classes.itemDetails}
            >
              <CardContent className={classes.itemContent}>
                <Typography variant="subtitle2">
                  {item.title}
                </Typography>
                <Typography variant="body2">
                  {item.description}
                </Typography>
                <ul className={classes.itemList}>
                {item.list.map((lItem, lIndex) => (
                  <li
                    key={`strategic-card-list-${index}-${lIndex}`}
                    className={classes.listItem}
                  >
                    <Typography variant="body2" className={classes.listItemText}>
                      {lItem}
                    </Typography>
                  </li>
                ))}
                </ul>
              </CardContent>
            </Grid>
            <Grid xs={12} sm={12} md={5} lg={4} xl={4}
              item
              className={classes.itemCover}
            >
              <CardMedia
                className={classes.itemCoverImage}
                image={item.image}
                title=""
              />
            </Grid>
          </Grid>
        </Card>
      ))}
      </div>
    </div>
  )
}

export default StrategicAdvisoryCards
