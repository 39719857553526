import * as React from "react"
import { PageProps } from "gatsby"

import Layout from "../../components/Layout"
import SEO from "../../components/Seo"
import SubHero from "../../components/SubHero"
import StrategicAdvisoryIntroduction from "../../sections/services/StrategicAdvisoryIntroduction"
import StrategicAdvisoryCards from "../../sections/services/StrategicAdvisoryCards"
import NextService from "../../sections/services/NextService"

import strategicAdvisoryBgUrl from "../../../media/strategic-advisory-banner-overlay.png"

const strategicAdvisoryTitle = "Strategic Advisory Services"
const strategicAdvisoryDescription = ""

const StrategicAdvisoryPage = ({ location }: PageProps) => (
  <Layout location={location}>
    <SEO title={strategicAdvisoryTitle} />
    <SubHero
      backgroundImage={strategicAdvisoryBgUrl}
      title={strategicAdvisoryTitle}
      description={strategicAdvisoryDescription}
    />
    <StrategicAdvisoryIntroduction />
    <StrategicAdvisoryCards />
    <NextService
      text="Next, learn about our Technology Implementation Services"
      link="services/technology-implementation"
    />
  </Layout>
)

export default StrategicAdvisoryPage
