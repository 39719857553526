import * as React from "react"
import { Typography } from "@material-ui/core"

import { useStyles } from "./styles"

const title =
  "Align people, process, data, and technology to transform your business"
const paragraph1 =
  "We collaborate with your organization to understand your business objectives and design a strategy that meets your unique needs and budget."
const paragraph2 = "Our strategic advisory services include:"

const StrategicAdvisoryIntroduction = () => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <div className={classes.innerWrapper}>
        <Typography variant="subtitle1" className={classes.title}>
          {title}
        </Typography>
        <Typography variant="body1" className={classes.description}>
          {paragraph1}
        </Typography>
        <Typography variant="h4" className={classes.subheading}>
          {paragraph2}
        </Typography>
      </div>
    </div>
  )
}

export default StrategicAdvisoryIntroduction
